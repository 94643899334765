"use strict";

/**
 * Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */

(function ($) {
    /**
     * @description Sanitize string
     * @param {string} input string to be sanitized
     * @returns {string} sanitized string
     */
    function sanitizeString(input) {
        var sanitizedString = input;
        if (input && typeof input === "string") {
            sanitizedString = input.replace(/['";\\()]/g, "");
        }
        return sanitizedString;
    }
    /**
     * @description Mounts secure inputs
     * @param {MercadoPago} mpInstance MercadoPago Instance
     * @param {Object} fields MercadoPago Secure Fields
     * @returns {Object} Elements
     */
    function mountInputs(mpInstance, fields) {
        $("#mercadoPagoCardOwner").attr("required", true);

        if (fields && fields.cardNumberElement && fields.expirationDateElement) {
            console.warn({ message: "Secure fields already mounted skipping." });
            return {
                cardNumberElement: fields.cardNumberElement,
                expirationDateElement: fields.expirationDateElement,
                securityCodeElement: fields.securityCodeElement
            };
        }
        const cardNumberElement = mpInstance.fields.create("cardNumber").mount("form-checkout__cardNumber");
        const expirationDateElement = mpInstance.fields.create("expirationDate", {
            placeholder: "MM/YY"
        }).mount("form-checkout__expirationDate");

        // SecurityCodeElement shouldn't be created more than once because both payments (saved/new) use this field
        let securityCodeElement;
        if (fields && fields.securityCodeElement) {
            securityCodeElement = fields.securityCodeElement;
        } else {
            securityCodeElement = mpInstance.fields.create("securityCode", {
                placeholder: "CVV"
            }).mount("form-checkout__securityCode");
        }

        return { cardNumberElement, expirationDateElement, securityCodeElement };
    }
    /**
     * @description Unmounts secure inputs
     * In order to create token mercado checks the fields that is created and validation fails if inputs are empty
     * We need to unmount unused inputs when using saved credit card for successful validation
     * @param {Object} fields MercadoPago Secure Fields
     * @returns {Object} {empty}
     */
    function unmountInputs(fields) {
        $("#mercadoPagoCardOwner").attr("required", false);

        if (fields && fields.cardNumberElement && fields.expirationDateElement) {
            fields.cardNumberElement.unmount();
            fields.expirationDateElement.unmount();
        }
        return { securityCodeElement: fields.securityCodeElement };
    }
    /**
     * @constructor
     * @classdesc Integration class
     */
    function MercadoPagoPage() {
        var that = this;
        var $content = $(".js-mercadopago-content");
        var $form = $content.find(".js-mp-form");
        this.preferences = $form.data("mpPreferences");
        this.resourceMessages = $form.data("mpResourceMessages");
        this.configuration = $form.data("mpConfiguration");
        if (!that.preferences) {
            console.warn({ message: "Preferences not set, initialization will be stop." });
            return;
        }
        // eslint-disable-next-line no-undef
        this.mp = new MercadoPago(that.preferences.publicKey, {locale: "es-AR"});
        this.bin = "";
        this.secureInputs = mountInputs(this.mp); // { cardNumberElement, expirationDateElement, securityCodeElement }

        var $elements = {
            paymentOptionTab: $(".js-payment-option-tab"),
            paymentTypeButton: $content.find(".js-toggle-payment-type"),
            customerCardsContainer: $content.find(".js-mp-customer-cards"),
            customerCard: $content.find(".js-mp-customer-card")
        };

        // Regular fields
        var fields = {
            cardType: {
                $el: $form.find(".js-mp-card-type"),
                disable: { other: false, stored: false },
                hide: { other: false, stored: true },
                errors: []
            },
            cardHolder: {
                $el: $form.find(".js-mp-card-holder"),
                disable: { other: true, stored: true },
                hide: { other: true, stored: true },
                errors: ["221", "316"]
            },
            cardNumber: {
                $el: $form.find(".js-mp-card-number"),
                disable: { other: true, stored: true },
                hide: { other: true, stored: true },
                errors: ["205", "E301"]
            },
            expirationDate: {
                $el: $form.find(".js-mp-expiration-date"),
                disable: { other: true, stored: true },
                hide: { other: true, stored: true },
                errors: ["208", "325", "209", "326"]
            },
            securityCode: {
                $el: $form.find(".js-mp-security-code"),
                disable: { other: true, stored: false },
                hide: { other: true, stored: false },
                errors: ["224", "E302", "E203"]
            },
            email: {
                $el: $form.find(".js-mp-email"),
                disable: { other: false, stored: true },
                hide: { other: false, stored: true },
                errors: ["email"]
            },
            phone: {
                $el: $form.find(".js-mp-phone"),
                disable: { other: false, stored: true },
                hide: { other: false, stored: true },
                errors: ["phone"]
            },
            saveCard: {
                $el: $form.find(".js-mp-save-card"),
                disable: { other: true, stored: true },
                hide: { other: true, stored: true },
                errors: []
            },
            useSameMailPhoneAsAddress: {
                $el: $form.find(".js-mp-use-same"),
                disable: { other: false, stored: true },
                hide: { other: false, stored: true },
                errors: []
            }
        };

        // Extended fields
        fields.issuer = {
            $el: $form.find(".js-mp-issuer"),
            disable: { other: true, stored: true },
            hide: { other: true, stored: false },
            errors: ["issuer"]
        };
        fields.financialinstitution = {
            $el: $form.find(".js-mp-financialinstitution"),
            disable: { other: true, stored: true },
            hide: { other: true, stored: true },
            cardTypeID: "pse",
            errors: ["mercadoPagoFinancialinstitution"]
        };
        fields.installments = {
            $el: $form.find(".js-mp-installments"),
            disable: { other: true, stored: false },
            hide: { other: true, stored: false },
            errors: ["installments"]
        };
        fields.docType = {
            $el: $form.find(".js-mp-doc-type"),
            disable: { other: false, stored: false },
            hide: { other: false, stored: false },
            errors: ["212", "322"]
        };
        fields.docNumber = {
            $el: $form.find(".js-mp-doc-number"),
            $wrapper: $form.find(".js-mp-doc-wrapper"),
            $label: $form.find(".js-mp-doc-label"),
            $tooltip: $form.find(".js-mp-doc-tooltip"),
            disable: { other: false, stored: false },
            hide: { other: false, stored: false },
            errors: ["214", "324", "2067"]
        };

        // Hidden fields
        Object.defineProperty(fields, "cardId", {
            enumerable: false,
            value: {
                $el: sanitizeString($form.find("#cardId"))
            }
        });
        Object.defineProperty(fields, "token", {
            enumerable: false,
            value: {
                $el: sanitizeString($form.find("#token"))
            }
        });

        var methods = {
            paymentOption: {
                /**
                 * @function handleChange
                 * @description Handle change of payment method and set initial state of payment tab
                 */
                handleChange: function () {
                    var $activeTab = $(this);
                    var methodId = $activeTab
                        .closest(".js-method-id")
                        .data("methodId");
                    var initialState = $form.data("mpInitial");

                    if (methodId === that.configuration.paymentMethodId) {
                        methods.paymentOption.setInitialState[
                            initialState + "Payment"
                        ]();
                    }
                },
                setInitialState: {
                    /**
                     * @function newPayment
                     * @description Set initial state for new payment section
                     */
                    newPayment: function () {
                        var paymentMethodInput = fields.cardType.$el.filter(
                            function () {
                                return (
                                    this.value
                                    === that.configuration.defaultCardType
                                );
                            }
                        );

                        // Check default card type
                        paymentMethodInput.prop("checked", true);
                        methods.card.handleTypeChange.call(
                            paymentMethodInput[0],
                            { data: { handleOther: true } }
                        );
                        $elements.customerCard.removeClass("selected-payment");
                        fields.cardId.$el.val("");
                        that.secureInputs = mountInputs(that.mp, that.secureInputs);
                    },
                    /**
                     * @function storedPayment
                     * @description Set initial state for stored payment section
                     */
                    storedPayment: function () {
                        var firstCard = $elements.customerCard.filter(":first");

                        // Select first card
                        methods.registeredCustomer.selectCustomerCard.call(firstCard[0]);

                        // Toggle payment type to stored
                        $elements.paymentTypeButton.data("togglePaymentType", "stored");
                        methods.registeredCustomer.togglePaymentType.call($elements.paymentTypeButton[0]);
                    },
                    /**
                     * @function restoreStoredPayment
                     * @description Restore stored payment section
                     */
                    restoreStoredPayment: function () {
                        var firstCard = $elements.customerCard.filter(":first");

                        // Select first card
                        methods.registeredCustomer.selectCustomerCard.call(firstCard[0]);

                        // Show and set disabled to false for stored payment fields
                        for (let i = 0; i < Object.keys(fields).length; i += 1) {
                            var field = Object.keys(fields)[i];
                            if (!fields[field].hide.stored) {
                                fields[field].$el
                                    .closest(".js-mp-container")
                                    .removeClass("checkout-hidden");
                            }
                            if (!fields[field].disable.stored) {
                                fields[field].$el.prop("disabled", false);
                            }
                        }
                    }
                }
            },
            token: {
                validateFinancialInstution: function () {
                    const financialinstitutionEl = fields.financialinstitution.$el;
                    if (financialinstitutionEl.attr("required")) {
                        let fieldValueLength = financialinstitutionEl.val().length;
                        if (+fieldValueLength === 0) {
                            financialinstitutionEl.next(".invalid-feedback").show();
                            return false;
                        }

                        financialinstitutionEl.next(".invalid-feedback").hide();
                        return true;
                    }
                    return true;
                },
                validateInstallments: function () {
                    const installmentsEl = fields.installments.$el;
                    if (installmentsEl.attr("required")) {
                        const value = installmentsEl.find(":selected").val();
                        if (!value) {
                            installmentsEl.next(".invalid-feedback").show();
                            return false;
                        }

                        installmentsEl.next(".invalid-feedback").hide();
                        return true;
                    }
                    return true;
                },
                validateCardHolder: function () {
                    const cardHolderEl = fields.cardHolder.$el;
                    if (cardHolderEl.attr("required")) {
                        const fieldValueLength = cardHolderEl.val().length;
                        if (+fieldValueLength === 0) {
                            cardHolderEl.next(".invalid-feedback").show();
                            return false;
                        }

                        cardHolderEl.next(".invalid-feedback").hide();
                        return true;
                    }
                    return true;
                },
                validateDocNumber: function () {
                    const docNumberEl = fields.docNumber.$el;
                    if (
                        docNumberEl.attr("required")
                        && docNumberEl.is(":visible")
                        && docNumberEl.is(":enabled")
                    ) {
                        let errorCode;
                        let fieldValueLength = docNumberEl.val().length;
                        if (+fieldValueLength === 0) {
                            errorCode = "214";
                        } if (
                            fieldValueLength > docNumberEl.attr("maxlength")
                            || fieldValueLength < docNumberEl.attr("minlength")
                        ) {
                            errorCode = "324";
                        }

                        if (errorCode) {
                            methods.token.errorResponse(errorCode);
                            return false;
                        }

                        docNumberEl.next(".invalid-feedback").hide();
                        return true;
                    }
                    return true;
                },
                validateSecureFields: function (errors) {
                    const securityCodeErrors = errors.filter((error) => error.field === "securityCode");
                    const creditCardNumberErrors = errors.filter((error) => error.field === "cardNumber");
                    const expirationDateErrors = errors.filter((error) => error.field === "expirationDate");

                    if (securityCodeErrors.length) {
                        fields.securityCode.$el.find(".invalid-feedback").focus().show();
                    } else {
                        fields.securityCode.$el.find(".invalid-feedback").hide();
                    }

                    if (creditCardNumberErrors.length) {
                        fields.cardNumber.$el.find(".invalid-feedback").focus().show();
                    } else {
                        fields.cardNumber.$el.find(".invalid-feedback").hide();
                    }

                    if (expirationDateErrors.length) {
                        fields.expirationDate.$el.find(".invalid-feedback").focus().show();
                    } else {
                        fields.expirationDate.$el.find(".invalid-feedback").hide();
                    }
                },
                /**
                 * @function checkSecurityCodeLength
                 * @description Checks the security code length from the payment response
                 * @param {string} cardType cardType
                 * @param {Object} paymentResponse paymentResponse
                 * @throws {Array} An array with the error details if the security code length is invalid
                 * @returns {boolean} boolean
                 */
                checkSecurityCodeLength: function (cardType, paymentResponse) {
                    const isAmex = cardType === "amex";
                    const securityCodeLengthIsValid = isAmex ? paymentResponse.security_code_length === 4 : paymentResponse.security_code_length === 3;
                    if (securityCodeLengthIsValid) {
                        return true;
                    }

                    /**
                     * This throw is a workaround for mercado sdk because mercado allows you to use
                     * 4 character cvv for visa, mastercard or 3 character cvv for amex.
                     * SDK only checks whether the security code is 3 or 4 characters, regardless of card type.
                     * We are throwing an array to simulate fields.createCardToken method throws an error.
                     */
                    // eslint-disable-next-line no-throw-literal
                    throw [{
                        cause: "invalid_length",
                        field: "securityCode"
                    }];
                },
                /**
                 * @function populate
                 * @description Create token and populate field with value during submit
                 * @param {Event} event event
                 * @param {Object} eventData event data
                 */
                populate: async function (event, eventData) {
                    // Continue default flow
                    let formIsValid = true;
                    if (eventData && eventData.continue) {
                        return;
                    }
                    // Stop default flow
                    event.stopImmediatePropagation();
                    const checkedCardType = fields.cardType.$el.filter(":checked");
                    const isOtherPaymentMethod = checkedCardType.data("mpCardType") === that.configuration.otherPaymentMethod;
                    const cardType = checkedCardType.val();
                    const paymentTypeId = $("#mpPaymentTypeId").val(); // credit_card, bank_transfer, ticket
                    const isBankTransfer = paymentTypeId === "bank_transfer";

                    if (isOtherPaymentMethod) {
                        const docNumberValid = methods.token.validateDocNumber();
                        const financialinstitutionValid = methods.token.validateFinancialInstution();

                        // If it is bank transfer we need extra input for financial institution
                        if (isBankTransfer ? (!docNumberValid || !financialinstitutionValid) : !docNumberValid) {
                            return;
                        }

                        var submitPayment = $(".next-step-button .submit-payment");
                        fields.token.$el.val("");
                        submitPayment.trigger("click", { continue: true });
                        return;
                    }
                    const installmentsValid = methods.token.validateInstallments();
                    const docNumberValid = methods.token.validateDocNumber();
                    const cardHolderValid = methods.token.validateCardHolder();
                    if (!docNumberValid || !cardHolderValid || !installmentsValid) {
                        formIsValid = false;
                    }

                    var selectPaymentCard = $elements.customerCard.filter(".selected-payment");
                    let paymentResponse;
                    if (selectPaymentCard && selectPaymentCard.length === 1) {
                        var id = selectPaymentCard.data("mp-customer-card").toString();
                        fields.cardId.$el.val(id);
                        try {
                            paymentResponse = await that.mp.fields.createCardToken({ cardId: id });
                            methods.token.checkSecurityCodeLength(cardType, paymentResponse);
                        } catch (errors) {
                            formIsValid = false;
                            methods.token.validateSecureFields(errors);
                            return;
                        }
                    } else {
                        var docTypeElement = document.getElementById("docType");
                        var identificationType = docTypeElement && docTypeElement.value;
                        var identificationNumber = fields.docNumber.$el.val();
                        var cardholderName = fields.cardHolder.$el.val();
                        try {
                            paymentResponse = await that.mp.fields.createCardToken({ cardholderName, identificationType, identificationNumber });
                            methods.token.checkSecurityCodeLength(cardType, paymentResponse);
                        } catch (errors) {
                            formIsValid = false;
                            methods.token.validateSecureFields(errors);
                            return;
                        }
                    }

                    if (paymentResponse && paymentResponse.status === "active" && formIsValid) {
                        methods.token.successResponse(paymentResponse);
                    } else {
                        methods.token.errorResponse();
                    }
                },

                /**
                 * @function successResponse
                 * @description Success callback for token creation
                 * @param {Object} serviceResponse service response
                 */
                successResponse: function (serviceResponse) {
                    var submitPayment = $(".next-step-button .submit-payment");

                    if (serviceResponse && serviceResponse.id) {
                        fields.token.$el.val(serviceResponse.id);
                    } else {
                        console.warn("Token is not created or using a saved credit card.");
                    }
                    submitPayment.trigger("click", { continue: true });
                },
                /**
                 * @function errorResponse
                 * @description Error callback for token creation
                 * @param {string} errorCode error code
                 */
                errorResponse: function (errorCode) {
                    var errorMessages = $form.data("mpErrorMessages");
                    var errorField;

                    // Set error code message if found, otherwise set default error message
                    var errorMessage = errorMessages[errorCode]
                        ? errorMessages[errorCode]
                        : errorMessages.default;
                    Object.keys(fields).forEach(function (index) {
                        var field = fields[index];
                        if (
                            field.errors
                            && field.errors.indexOf(errorCode) !== -1
                        ) {
                            errorField = field;
                        }
                    });
                    if (errorField) {
                        errorField.$el
                            .next(".invalid-feedback")
                            .focus()
                            .show()
                            .text(errorMessage);
                    } else {
                        $(".error-message").show();
                        $(".error-message-text").text(errorMessage);
                    }
                }
            },

            card: {
                /**
                 * @function handleTypeChange
                 * @description Handle credit card type change
                 * @param {Event} e event
                 */
                handleTypeChange: function (e) {
                    var $el = $(this);
                    var issuerMandatory = $el.data("mpIssuerRequired");
                    var isOtherType = $el.data("mpCardType") === that.configuration.otherPaymentMethod;
                    var paymentTypeID = sanitizeString($el.val()); // master, visa, pse, boleto
                    var mpPaymentTypeId = $el.data("mpPaymentTypeId"); // credit_card, bank_transfer, ticket

                    // Handle fields for other payment method
                    if (e.data.handleOther) {
                        methods.card.handleOtherType(
                            isOtherType,
                            paymentTypeID
                        );
                    }

                    if (that.preferences.enableInstallments === true && !isOtherType) {
                        methods.installment.set();

                        // Set issuer info
                        if (issuerMandatory) {
                            methods.issuer.set($el);
                            fields.issuer.$el.prop("disabled", false);
                            fields.issuer.$el
                                .off("change")
                                .on("change", methods.installment.setByIssuerId);
                        } else {
                            fields.issuer.$el.prop("disabled", true);
                        }
                        $("#installments").attr("required", true);
                    } else {
                        $("#installments").attr("required", false);
                    }
                    $("#mpPaymentTypeId").val(mpPaymentTypeId);
                },
                /**
                 * @function handleOtherType
                 * @description Toggle other payment method
                 * @param {boolean} isOtherType is other type
                 * @param {string} cardTypeID cart type ID
                 */
                handleOtherType: function (isOtherType, cardTypeID) {
                    Object.keys(fields).forEach((field) => {
                        if (Object.keys(fields[field].$el).length > 0) {
                            var fieldsField = fields[field];
                            if (fieldsField.hide.other) {
                                fieldsField.$el
                                    .closest(".js-mp-container")
                                    .toggleClass("checkout-hidden", isOtherType);
                            }
                            if (fieldsField.disable.other) {
                                fieldsField.$el.prop("disabled", isOtherType);
                            }

                            if (fieldsField.cardTypeID) {
                                fieldsField.$el.prop(
                                    "disabled",
                                    fieldsField.cardTypeID !== cardTypeID
                                );
                                fieldsField.$el
                                    .closest(".js-mp-container")
                                    .toggleClass(
                                        "checkout-hidden",
                                        fieldsField.cardTypeID !== cardTypeID
                                    );
                            }
                        }
                    });
                },

                guessingPaymentMethod: async function () {
                    try {
                        if (!that.bin) {
                            console.warn({ message: "bin is not provided in guessingPaymentMethod, returning" });
                            return;
                        }
                        const { results } = await that.mp.getPaymentMethods({ bin: that.bin });
                        const firstGuess = results[0] || null;
                        const mercadoPagoCardNumber = $("#mercadoPagoCardNumber");
                        mercadoPagoCardNumber.value = firstGuess;
                    } catch (error) {
                        console.error({ message: "Error occurred while trying to guessPaymentMethod", error });
                    }
                }
            },

            installment: {
                /**
                 * @function set
                 * @description Set installments
                 * @param {string} $element $element
                 */
                set: async function () {
                    // Set installments info
                    try {
                        if (!that.bin) {
                            console.warn({ message: "bin is not provided in installment.set, returning" });
                            return false;
                        }
                        const installments = await that.mp.getInstallments({
                            amount: $form.data("mpCartTotal").toString(),
                            bin: that.bin,
                            paymentTypeId: "credit_card"
                        });
                        methods.installment.setInstallmentOptions(installments);
                        return installments;
                    } catch (error) {
                        console.error({ message: "Error occurred while trying to set installment", error });
                        return false;
                    }
                },
                /**
                 * @function setInstallmentOptions
                 * @description Sets the installments
                 * @param {Array} installments installments
                 */
                setInstallmentOptions: function (installments) {
                    fields.installments.$el.find("option").remove();
                    const installmentsIsNotValid = !installments || !Array.isArray(installments) || installments.length <= 0;

                    if (installmentsIsNotValid) {
                        return;
                    }

                    var $defaultOption = $(
                        new Option(
                            that.resourceMessages.defaultInstallments,
                            ""
                        )
                    );
                    fields.installments.$el.append($defaultOption);

                    if (installments[0].payer_costs) {
                        $.each(installments[0].payer_costs, function (i, item) {
                            fields.installments.$el.append(
                                $("<option>", {
                                    value: item.installments,
                                    text:
                                        item.recommended_message
                                        || item.installments
                                })
                            );

                            if (
                                fields.installments.$el.val() !== ""
                                && fields.installments.$el.val()
                                === item.installments
                            ) {
                                fields.installments.$el.val(item.installments);
                            }
                        });
                    }
                },
                /**
                 * @function setByIssuerId
                 * @description Set installments using issuer ID
                 * @param {HTMLElement} $element paymentTypeId Element
                 */
                setByIssuerId: async function () {
                    var issuerId = $(this).val();

                    if (!issuerId || issuerId === "-1") {
                        console.warn({ message: "issuerId is not valid, returning" });
                        return false;
                    }
                    try {
                        const installments = await that.mp.getInstallments({
                            amount: $form.data("mpCartTotal").toString(),
                            paymentTypeId: "credit_card",
                            issuerId: issuerId,
                            bin: that.bin
                        });
                        methods.installment.setInstallmentOptions(installments);
                        return installments;
                    } catch (error) {
                        console.error({ message: "Error occurred while trying to setByIssuerId", error });
                        return false;
                    }
                }
            },

            issuer: {
                /**
                 * @function set
                 * @description Set issuer
                 * @param {jQuery} $element element
                 */
                set: async function ($element) {
                    var paymentMethodId = $element.val();
                    try {
                        if (!that.bin) {
                            console.warn({ message: "bin is not provided in issuer.set, returning" });
                            return false;
                        }
                        const issuers = await that.mp.getIssuers({ paymentMethodId, bin: that.bin });
                        methods.issuer.setIssuerOptions(issuers);
                        return issuers;
                    } catch (error) {
                        console.error({ message: "Error occurred hile trying to set issuer", error });
                        return false;
                    }
                },
                setIssuerOptions : function (issuers) {
                    const toSetIssuer = issuers[0];
                    // Remove all values besides the placeholder one
                    fields.issuer.$el.find("option").remove();

                    $.each(issuers, function (i, item) {
                        fields.issuer.$el.append($("<option>", {
                            value: item.id,
                            text : item.name
                        }));
                    });

                    fields.issuer.$el.val(toSetIssuer.id);
                }
            },

            docType: {
                /**
                 * @function init
                 * @description Init identification document type
                 */
                init: async function () {
                    /**
                     * @function createSelectOptions
                     * @description Populates select
                     * @param {HTMLElement} elem Element to modify
                     * @param {Object} options Options
                     * @param {Object} labelsAndKeys Label and ID
                     */
                    function createSelectOptions(elem, options, labelsAndKeys = { label: "name", value: "id" }) {
                        const elemRef = elem;
                        const { label, value } = labelsAndKeys;

                        elemRef.options.length = 0;
                        const tempOptions = document.createDocumentFragment();

                        options.forEach(option => {
                            const optValue = option[value];
                            const optLabel = option[label];

                            const opt = document.createElement("option");
                            opt.value = optValue;
                            opt.textContent = optLabel;

                            tempOptions.appendChild(opt);
                        });

                        elemRef.appendChild(tempOptions);
                    }
                    try {
                        const identificationTypes = await that.mp.getIdentificationTypes();

                        const identificationTypeElement = document.getElementById("docType");

                        createSelectOptions(identificationTypeElement, identificationTypes);
                    } catch (e) {
                        console.error("Error getting identificationTypes: ", e);
                    }
                }
            },

            docNumber: {
                /**
                 * @function setRange
                 * @description Set range identification document number
                 */
                setRange: function () {
                    var $selectedOption = $(this).find("option:selected");
                    var minLength = $selectedOption.data("minLength");
                    var maxLength = $selectedOption.data("maxLength");

                    // Set label
                    var labelSecondPart = $selectedOption.val() === that.configuration.docTypeDNI
                        ? that.resourceMessages.docNumberLabelDNI
                        : that.resourceMessages.docNumberLabelOther;
                    fields.docNumber.$label.text(
                        that.resourceMessages.docNumberLabel
                        + " "
                        + labelSecondPart
                    );

                    // Set range
                    fields.docNumber.$wrapper.addClass("required");
                    fields.docNumber.$el.attr("maxlength", maxLength);
                    fields.docNumber.$el.attr("minlength", minLength);

                    // Set tooltip
                    fields.docNumber.$tooltip.text(
                        that.resourceMessages.docNumberTooltip
                            .replace("{0}", minLength)
                            .replace("{1}", maxLength)
                    );
                }
            },

            registeredCustomer: {
                /**
                 * @function togglePaymentType
                 * @description Toggle payment type (new or stored)
                 * @param {Event} event event
                 */
                togglePaymentType: function (event) {
                    var $el = $(this);
                    var isNew = $el.data("togglePaymentType") === "new";

                    $elements.customerCardsContainer.toggleClass(
                        "checkout-hidden",
                        isNew
                    );

                    // Disable and remove value to properly create token
                    fields.cardId.$el.prop("disabled", isNew);
                    Object.keys(fields).forEach((field) => {
                        if (fields[field].hide.stored) {
                            fields[field].$el
                                .closest(".js-mp-container")
                                .toggleClass("checkout-hidden", !isNew);
                        }
                        if (fields[field].disable.stored) {
                            fields[field].$el.prop("disabled", !isNew);
                        }
                    });

                    // Set initial states
                    if (isNew) {
                        fields.cardId.$el.val("");
                        that.bin = "";
                        methods.paymentOption.setInitialState.newPayment();
                    } else {
                        that.secureInputs = unmountInputs(that.secureInputs);
                    }
                    // Only when triggered from event (to avoid recursion)
                    if (event && !isNew) {
                        methods.paymentOption.setInitialState.restoreStoredPayment();
                    }

                    // Change to opposite
                    $el.data("togglePaymentType", isNew ? "stored" : "new");
                    $el.text(
                        $el.data((isNew ? "stored" : "new") + "PaymentText")
                    );
                },
                /**
                 * @function selectCustomerCard
                 * @description Select store credit card
                 */
                selectCustomerCard: function () {
                    var $el = $(this);
                    $elements.customerCard.removeClass("selected-payment");
                    $el.addClass("selected-payment");
                    fields.cardId.$el.val($el.data("mpCustomerCard"));
                    that.bin = $el.data("mp-bin").toString();
                    var paymentMethodInput = fields.cardType.$el.filter(
                        function () {
                            return this.value === $el.data("mpMethodId");
                        }
                    );
                    paymentMethodInput.prop("checked", true);
                    methods.card.handleTypeChange.call(paymentMethodInput[0], {
                        data: { handleOther: false }
                    });
                }
            }
        };

        /**
         * @function listenPlaceOrderComplete
         * @description This function is necessary to listen the PlaceOrder complete and do a redirection based on
         * continue url, redirect flag
         */
        function listenPlaceOrderComplete() {
            $(document).ajaxComplete(function (event, xhr, settings) {
                const isPlaceOrder = settings && settings.url && settings.url.indexOf("CheckoutServices-PlaceOrder") > -1;
                const shouldRedirect = xhr && xhr.responseJSON && xhr.responseJSON.redirect && xhr.responseJSON.continueUrl;
                if (isPlaceOrder && shouldRedirect) {
                    window.location.href = xhr.responseJSON.continueUrl;
                }
            });
        }

        /**
         * @function events
         * @description Init events
         */
        function events() {
            $elements.paymentOptionTab.on(
                "click",
                methods.paymentOption.handleChange
            ); // By click
            fields.cardType.$el.on(
                "change",
                { handleOther: true },
                methods.card.handleTypeChange
            );
            fields.docType.$el.on("change", methods.docNumber.setRange);
            $elements.paymentTypeButton.on(
                "click",
                methods.registeredCustomer.togglePaymentType
            );
            $elements.customerCard.on(
                "click",
                methods.registeredCustomer.selectCustomerCard
            );
            $(".next-step-button .submit-payment").on(
                "click",
                methods.token.populate
            );
            that.secureInputs.cardNumberElement.on("binChange", (data) => {
                const { bin } = data;
                that.bin = bin;
                methods.card.guessingPaymentMethod();
                methods.installment.set();

                // Updated the issuer field if necessary
                const $selectedCardType = fields.cardType.$el.filter(":checked");
                const issuerMandatory = $selectedCardType.data("mpIssuerRequired");
                const isOtherType = $selectedCardType.data("mpCardType") === that.configuration.otherPaymentMethod;

                if (that.preferences.enableInstallments === true && !isOtherType) {
                    // Set issuer info
                    if (issuerMandatory) {
                        methods.issuer.set($selectedCardType);
                        fields.issuer.$el.prop("disabled", false);
                        fields.issuer.$el
                            .off("change")
                            .on("change", methods.installment.setByIssuerId);
                    } else {
                        fields.issuer.$el.prop("disabled", true);
                    }
                    $("#installments").attr("required", true);
                } else {
                    $("#installments").attr("required", false);
                }
            });
            listenPlaceOrderComplete();
        }

        /**
         * @function extendPaymentMethod
         * @description Push active class name if mercado is the only payment method available
         */
        function extendPaymentMethod() {
            const paymentMethodTabs = $(".tab-pane");
            if (paymentMethodTabs.length === 1) {
                $(".js-mercadopago-content").addClass("active");
            }
        }

        /**
         * @function init
         * @description Init integration
         */
        this.init = function () {
            if ($content.length > 0) {
                if (that.preferences.enableDocTypeNumber) {
                    methods.docType.init();
                }
                events();
                methods.paymentOption.handleChange.call(
                    $elements.paymentOptionTab.filter(".enabled")
                ); // Initial
                extendPaymentMethod();
            }
        };
    }

    $(document).ready(new MercadoPagoPage().init);
}(jQuery));
